body {
  font: 22px "Century Gothic", sans-serif;
  margin: 20px;
}

ol, ul {
  padding-left: 30px;
}

p {  
  display: block;
  margin-block-start: 0.7em;
  margin-block-end: 0.7em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.status-info {
  margin-left: 20px;
  color: rgb(208,33,41);
}

.board {
  display: flex;
  flex-direction: row;
  width: 16.5em;
}

.title {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-left: auto;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: rgb(208,33,41);
}

.button-container {
  margin-left: 1em;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  margin-bottom: 10px;
}

.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 54px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 54px;
}

.square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.game {
  display: flex;
  flex-direction: column;
  margin-top: 1.2em;
  margin-left: auto;
  justify-content: center;
  align-items: center;
  min-height: 15vh;
}

.scores-container {
  margin-right: 10em;
  color: rgb(208,33,41);
}

.create-button {
  margin-right: 5px;
  width: 4.5em;
  height: 2.0em;
}

.join-button {
  width: 4.5em;
  height: 2.0em;
}

.input-class{
  margin-top: -0.1em;
}

.title-class {
  font-size: 1.5rem; 
}

.popup-class{
  height: 165px; 
}

.join-button-class {
  margin: .3125em; 
  padding: .5em 1.8em; 
  margin-bottom: 1em; 
}

.button-class {
  margin: .3125em; 
  padding: .5em 1.8em; 
}
